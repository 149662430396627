import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/logo.png"

const Header = ({ siteTitle }) => (
  <header
    style={{
      color: "hsla(0, 0%, 0%, 0.8)",
      maxWidth: 1080,
      width: "100%",
      margin: `1.45rem auto`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
    }}
  >
    <div
      style={{
        padding: `0 1rem`,
        flex: 3,
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link
          to='/'
          style={{
            textDecoration: `none`,
          }}
        >
          <img
            src={logo}
            alt='ViSpecs'
            style={{ height: 80, objectFit: "scale-down", minWidth: 200 }}
          />
        </Link>
      </h1>
    </div>
    <div
      style={{
        flex: 5,
        margin: "1.5rem 0",
      }}
    >
      <ul
        style={{
          margin: "auto 1rem",
          listStyleType: "none",
          display: "flex",
          justifyContent: "right",
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        <li style={{ margin: "0 0.5rem" }}>
          <Link
            to='/contact'
            style={{
              textDecoration: `none`,
              color: "hsla(0, 0%, 0%, 0.8)",
            }}
          >
            Contact
          </Link>
        </li>
        <li style={{ margin: "0 0 0 0.5rem" }}>
          <Link
            to='/about'
            style={{
              textDecoration: `none`,
              color: "hsla(0, 0%, 0%, 0.8)",
            }}
          >
            About
          </Link>
        </li>
      </ul>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
