/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import Header from "./header"
import "./layout.css"
import phone from "../images/phone.png"
import bg from "../images/3334702.jpg"

import Helmet from "react-helmet"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        minHeight: "100vh",
        backgroundImage: `url(${bg})`,
        backgroundSize: "100% auto",
      }}
    >
      <Helmet>
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
      </Helmet>
      <Header siteTitle={data.site.siteMetadata.title} />
      <div
        style={{
          margin: `0 auto`,
          padding: `0 1.0875rem`,
          flex: 1,
          display: "flex",
        }}
      >
        <main
          style={{
            display: "flex",
            flex: 1,
            flexWrap: "wrap",
          }}
        >
          <img
            src={phone}
            style={{
              flex: 3,
              alignSelf: "end",
              objectFit: "scale-down",
              minWidth: 200,
            }}
          />
          <div
            id='content'
            style={{
              flex: 5,
              minWidth: 200,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                padding: "1rem 1rem 1rem 2rem",
                display: "flex",
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {children}
            </div>
            <footer style={{ padding: "0 1rem 0 2rem" }}>
              © {new Date().getFullYear()},{" "}
              <a href='https://vispecs.com'>ViSpecs GmbH</a> |{" "}
              <Link to='/datenschutz'>Datenschutzerklärung</Link> |{" "}
              <Link to='/impressum'>Impressum</Link>
            </footer>
          </div>
        </main>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
